import { useCallback, useEffect, useRef } from 'react';

function useShortcutSearchInput() {
    const searchInputRef = useRef<HTMLInputElement>(null);

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        const isCtrlOrCmd = event.metaKey || event.ctrlKey;
        const isKKey = event.code === 'KeyK';
        if (isCtrlOrCmd && isKKey && searchInputRef.current) {
            event.preventDefault();
            searchInputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    return searchInputRef;
}

export default useShortcutSearchInput;
