import { makeStyles } from 'tss-react/mui';

export default makeStyles()({
    root: {
        '& .MuiBackdrop-root': {
            background: 'rgba(0, 0, 0, 0.5)'
        }
    },
    paper: {
        padding: 24,
        maxWidth: 312
    },
    close: {
        position: 'fixed',
        right: 16,
        top: 16,
        background: '#F5F5F5',
        borderRadius: 8
    },
    notification: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: '#FFF',
        gap: 24
    },
    notificationButton: {
        display: 'block',
        padding: '12px 20px',
        border: '1px solid #fff',
        borderRadius: 8,
        textDecoration: 'none',
        color: '#FFF'
    }
});
