import { makeStyles } from 'tss-react/mui';

export default makeStyles()({
    root: {
        textAlign: 'center'
    },
    title: {
        fontWeight: 700,
        fontSize: 20,
        color: '#232323',
        marginBottom: 8
    },
    subtitle: {
        fontWeight: 500,
        fontSize: 16,
        color: '#5F5F5F',
        maxWidth: 312,
        marginBottom: 24
    },
    selector: {
        flex: 1,
        marginBottom: 20,
        textAlign: 'left',
        borderRadius: 18
    },
    selectorRoot: {
        borderRadius: 18,
        background: 'transparent!important',
        border: '1px solid #F5F6F8'
    },
    selectorInput: {
        padding: '18px 16px !important',
        borderRadius: 18,
        color: '#38383A',
        paddingTop: 0,
        background: 'transparent',
        borderColor: '#F5F6F8',
        '&:focus': {
            borderRadius: 8,
            backgroundColor: 'transparent',
            borderColor: '#F5F6F8'
        }
    },
    selectorNotchedOutline: {
        borderColor: '#F5F6F8'
    },

    menuList: {
        padding: '18px 24px'
    },
    menuItem: {
        fontWeight: 400,
        fontSize: 13,
        lineHeight: '16px',
        color: '#282D31',
        padding: '12px 24px 12px 16px',
        borderRadius: 8
    },

    email: {
        marginBottom: 24
    },

    button: {
        background: '#282D31',
        borderRadius: 18,
        padding: '20px 56px',
        color: '#FFFFFF',
        fontSize: 16,
        lineHeight: '16px',
        fontWeight: 500,
        textTransform: 'none',
        '&:hover': {
            background: 'rgba(40,45,49,0.9)'
        }
    }
});
