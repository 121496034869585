import { makeStyles } from 'tss-react/mui';

const baseSize = 24;
const padding = 6;

export default makeStyles()({
    root: {
        width: baseSize,
        height: baseSize,
        position: 'relative'
    },
    icon: {
        width: baseSize,
        height: baseSize
    },
    button: {
        position: 'absolute',
        width: baseSize + 2 * padding,
        height: baseSize + 2 * padding,
        padding,
        top: -padding,
        right: -padding,
        bottom: -padding,
        left: -padding
    }
});
