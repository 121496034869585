import { makeStyles } from 'tss-react/mui';

export default makeStyles()({
    button: {
        color: '#8385A3',
        padding: '6px 8px',
        border: '1px solid #fff',
        borderRadius: 8,
        fontSize: 12,
        textTransform: 'none',
        background: 'transparent',
        '&:hover': {
            background: '#FFF'
        }
    },
    buttonActive: {
        background: '#FFF'
    },
    icon: {
        visibility: 'visible'
    },
    hidden: {
        visibility: 'hidden'
    }
});
