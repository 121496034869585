export enum Presets {
    ASAP = 'asap',
    EVERY_DAY = 'every_day',
    EVERY_MONDAY = 'every_monday',
    FIRST_DAY_OF_MONTH = 'first_day_of_month',
    CUSTOM = 'custom'
}
export enum Intervals {
    DAY_OF_WEEK = 'day_of_week',
    NTH_DAY_IN_MONTH = 'nth_day_in_month',
    NTH_MONTH_IN_YEAR = 'nth_month_in_year'
}

export interface SchedulerState {
    preset: Presets;
    time: number;
    interval: Intervals;
    intervalValue: number | number[];
}
