import React, { useMemo } from 'react';

import useStyles from './styles';

interface Props {
    source?: string;
    substring?: string;
    caseSensitive?: boolean;
    className?: string;
    classes?: {
        root?: string;
        matched?: string;
        unmatched?: string;
        highlight?: string;
    };
}

const HighlightSubstring: React.VFC<Props> = ({
    source = '',
    substring = '',
    caseSensitive = false,
    className,
    classes,
    ...restProps
}) => {
    const { classes: internalClasses, cx } = useStyles();

    const normalizedSource = useMemo(() => (caseSensitive ? source : source.toLowerCase()), [source, caseSensitive]);
    const normalizedSubstring = useMemo(() => (caseSensitive ? substring.trim() : substring.trim().toLowerCase()), [
        substring,
        caseSensitive
    ]);

    const [before, match, after] = useMemo(() => {
        const index = normalizedSource.indexOf(normalizedSubstring);
        if (index >= 0 && !!normalizedSubstring) {
            const { length } = normalizedSubstring;
            return [
                source.substring(0, index),
                source.substring(index, index + length),
                source.substring(index + length)
            ];
        }

        return [];
    }, [normalizedSource, normalizedSubstring, source]);

    if (!source) {
        return null;
    }

    return (
        <span
            {...restProps}
            className={cx(
                classes?.root,
                classes?.matched && { [classes.matched]: !!match },
                classes?.unmatched && { [classes.unmatched]: !match },
                className
            )}
        >
            {match ? (
                <>
                    {before}
                    <span data-highlight className={cx(internalClasses.highlight, classes?.highlight)}>
                        {match}
                    </span>
                    {after}
                </>
            ) : (
                source
            )}
        </span>
    );
};

export default React.memo(HighlightSubstring);
