import React from 'react';

import { InputBase, InputBaseProps } from '@mui/material';

import useStyles from './styles';

type Props = InputBaseProps;

const BaseInput: React.ForwardRefRenderFunction<HTMLDivElement, Props> = ({ className, ...restProps }, ref) => {
    const { classes, cx } = useStyles();

    return <InputBase fullWidth {...restProps} inputRef={ref} className={cx(classes.input, className)} />;
};

export default React.memo(React.forwardRef(BaseInput));
