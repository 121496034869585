export enum View {
    SUBSCRIPTION = 'subscription',
    LOGIN = 'login'
}

export enum LoginView {
    SIGN_IN = 'signIn',
    SIGN_UP = 'signUp',
    RECOVER = 'recover'
}

export const PendingSubscriptionKey = 'PENDING_SUBSCRIPTION';
