import React, { useCallback, useState } from 'react';

import { AutocompleteInputChangeReason, Fade, MenuItem, MenuList, useAutocomplete } from '@mui/material';

import useRefSetter from 'hooks/use-ref-setter';
import BaseInput from 'main/components/SearchBar/components/BaseInput';
import HighlightSubstring from 'main/components/SearchBar/components/HighlightSubstring';
import { emptyArray } from 'main/components/SearchBar/constants';
import { ChangeTrigger } from 'main/components/SearchBar/types';

import useStyles from './styles';

interface Props {
    id?: string;
    placeholder?: string;
    className?: string;
    onChange?: (value: string, trigger: ChangeTrigger) => void;
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
    value?: string;
    options?: Option[];
    loading?: boolean;
    classes?: {
        root?: string;
        input?: string;
        list?: string;
        option?: string;
    };
}

interface Option {
    value: string;
}

const reasonToTriggerMap: Record<AutocompleteInputChangeReason, ChangeTrigger> = {
    clear: ChangeTrigger.select,
    reset: ChangeTrigger.select,
    input: ChangeTrigger.input
};

const transitionTimeout = { enter: 150, exit: 50 };

const AutoCompleteInput: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
    { id, onChange, onKeyDown, value, placeholder, options = emptyArray, className, classes, loading },
    inputRef
) => {
    const { classes: internalClasses, cx } = useStyles();
    const [isTyping, setIsTyping] = useState(false);

    const handleInputChange = useCallback(
        (event: React.ChangeEvent, value: string, reason: AutocompleteInputChangeReason) => {
            if (event) {
                onChange?.(value, reasonToTriggerMap[reason] || ChangeTrigger.input);
            }
        },
        [onChange]
    );

    const { getRootProps, getInputProps, getListboxProps, groupedOptions, getOptionProps, popupOpen } = useAutocomplete(
        {
            id,
            options,
            freeSolo: true,
            inputValue: value,
            onHighlightChange: (_event, option, reason) => setIsTyping(!option || reason !== 'keyboard'),
            onInputChange: handleInputChange,
            getOptionLabel: (it) => (typeof it === 'string' ? it : it.value)
        }
    );
    const inputProps = getInputProps();
    const setRef = useRefSetter(inputProps.ref, inputRef);
    return (
        <div {...getRootProps()} className={cx(internalClasses.root, classes?.root, className)}>
            <BaseInput
                placeholder={placeholder}
                className={classes?.input}
                {...(inputProps as object)}
                ref={setRef}
                onKeyDown={isTyping ? onKeyDown : inputProps.onKeyDown}
            />
            <Fade in={popupOpen && (groupedOptions.length > 0 || loading)} timeout={transitionTimeout}>
                <MenuList {...getListboxProps()} className={cx(internalClasses.list, classes?.list)}>
                    {groupedOptions.map((option, index) => {
                        return (
                            <MenuItem
                                key={index}
                                {...getOptionProps({ option, index })}
                                {...option}
                                className={cx(internalClasses.option, classes?.option)}
                            >
                                <HighlightSubstring
                                    source={option.value}
                                    substring={value}
                                    classes={{
                                        matched: internalClasses.matched,
                                        highlight: internalClasses.highlight
                                    }}
                                />
                            </MenuItem>
                        );
                    })}
                    {groupedOptions.length === 0 && loading ? (
                        <MenuItem disabled className={internalClasses.option}>
                            {'Loading...'}
                        </MenuItem>
                    ) : null}
                </MenuList>
            </Fade>
        </div>
    );
};

export default React.memo(React.forwardRef(AutoCompleteInput));
