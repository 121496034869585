import { makeStyles } from 'tss-react/mui';

export default makeStyles<{ compact?: boolean }>()((_theme, { compact }) => ({
    container: {
        display: 'flex',
        padding: compact ? '10px 16px 10px 16px' : '10px 16px 10px 20px',
        background: '#F0F3F4',
        borderRadius: 16,
        position: 'relative',
        gap: compact ? 8 : 16,
        flexDirection: compact ? 'column' : 'row',
        alignItems: compact ? 'flex-start' : 'center',
        width: compact ? undefined : '100%'
    },
    vertical: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 8
    },
    buttonGroup: {
        color: '#8385A3',
        display: 'flex',
        alignItems: 'center',
        gap: 16,
        width: compact ? '100%' : undefined,
        marginTop: compact ? 4 : undefined
    },
    button: {
        paddingTop: 8.5,
        paddingBottom: 8.5,
        border: 0
    },
    searchFieldRoot: {
        flex: 1,
        boxSizing: 'border-box',
        position: compact ? 'relative' : 'static',
        background: compact ? '#fff' : undefined,
        borderRadius: compact ? 12 : undefined,
        padding: compact ? '4px 12px 4px 12px' : undefined,
        width: compact ? '100%' : undefined
    },
    searchFieldList: {
        top: '100%',
        left: 0,
        right: 0
    },
    typeFieldRoot: {
        boxSizing: 'border-box',
        position: compact ? 'relative' : 'static',
        background: compact ? '#fff' : undefined,
        borderRadius: compact ? 12 : undefined,
        padding: compact ? '7px 12px 7px 12px' : undefined,
        width: compact ? '100%' : 121
    },
    typeFieldLabel: {
        fontSize: compact ? 14 : undefined,
        lineHeight: compact ? '24px' : undefined
    },
    typeFieldList: {
        top: '100%',
        left: 0
    },
    divider: {
        margin: 'auto 0',
        height: 16,
        background: '#DDDEF1'
    }
}));
