import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
    root: {
        position: 'relative'
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        whiteSpace: 'nowrap',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '140%',
        color: '#282D31',
        cursor: 'pointer',
        opacity: 1,
        transition: 'opacity 0.25s',
        '&:hover': {
            opacity: 0.8
        }
    },
    icon: {
        transition: 'transform 0.25s'
    },
    opened: {
        transform: 'rotate(180deg)'
    },
    list: {
        position: 'absolute',
        padding: '8px 16px 8px 8px',
        background: '#FFFFFF',
        borderRadius: 16,
        boxShadow: '0px 10px 40px 0px #C8D4DC59',
        top: '100%',
        left: 0,
        zIndex: theme.zIndex.tooltip
    },
    listItem: {
        fontWeight: 400,
        fontSize: 13,
        lineHeight: '16px',
        color: '#4D4D4D',
        padding: 8,
        display: 'flex',
        alignItems: 'center',
        gap: 6
    },
    radio: {
        width: 20,
        height: 20,
        borderRadius: '50%',
        border: '1px solid #E0E0E0',
        boxSizing: 'border-box',
        marginRight: 6
    },
    checked: {
        border: '4px solid #6161E1'
    },
    upgrade: {
        color: '#FF8B61'
    }
}));
