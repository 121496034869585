import { makeStyles } from 'tss-react/mui';

export default makeStyles()({
    button: {
        background: '#FFFFFF',
        padding: 7.5
    },
    icon: {
        visibility: 'visible'
    },
    hidden: {
        visibility: 'hidden'
    }
});
