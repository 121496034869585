import { makeStyles } from 'tss-react/mui';

export default makeStyles()({
    input: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '24px',
        color: '#282D31',
        caretColor: '#FF6600'
    }
});
