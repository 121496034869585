import React from 'react';

import { IconButton } from '@mui/material';

import useStyles from './styles';

import SearchIcon from '../../assets/search.svg';

interface Props {
    className?: string;
    onClick?: () => void;
    disabled?: boolean;
}

const SearchButton: React.FC<Props> = ({ onClick, className, disabled }) => {
    const { classes, cx } = useStyles();
    return (
        <div className={cx(classes.root, className)}>
            <IconButton className={classes.button} disabled={disabled} onClick={onClick} size="large">
                <SearchIcon className={classes.icon} />
            </IconButton>
        </div>
    );
};

export default React.memo(SearchButton);
