import React, { useCallback, useState } from 'react';

import { ClickAwayListener, MenuItem, MenuList, Zoom } from '@mui/material';

import ChevronIcon from 'main/components/SearchBar/assets/chevron.svg';
import { optionByValue, searchTypeOptions } from 'main/components/SearchBar/constants';
import { SearchType } from 'main/components/SearchBar/types';

import useStyles from './styles';

interface Props {
    value?: SearchType;
    onChange?: (value: SearchType) => void;
    className?: string;
    classes?: {
        root?: string;
        label?: string;
        list?: string;
        option?: string;
    };
}

const [defaultOption] = searchTypeOptions;

const SearchTypeSelect: React.FC<Props> = ({ className, classes, value = defaultOption.value, onChange }) => {
    const { classes: internalClasses, cx } = useStyles();

    const [isOpen, setIsOpen] = useState(false);

    const toggleIsOpen = useCallback(() => setIsOpen((state) => !state), []);
    const handleClose = useCallback(() => setIsOpen(false), []);

    const onSelect: React.MouseEventHandler = useCallback(
        (event) => {
            if (event.currentTarget instanceof HTMLLIElement) {
                onChange?.(event.currentTarget.dataset.value as SearchType);
                handleClose();
            }
        },
        [handleClose, onChange]
    );

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div className={cx(internalClasses.root, classes?.root, className)}>
                <div onClick={toggleIsOpen} className={cx(internalClasses.label, classes?.label)}>
                    {optionByValue[value].label}
                    <ChevronIcon className={cx(internalClasses.icon, { [internalClasses.opened]: isOpen })} />
                </div>
                <Zoom in={isOpen}>
                    <MenuList className={cx(internalClasses.list, classes?.list)}>
                        {searchTypeOptions.map((option) => {
                            return (
                                <MenuItem
                                    key={option.value}
                                    data-value={option.value}
                                    onClick={onSelect}
                                    className={cx(internalClasses.listItem, classes?.option)}
                                >
                                    <span
                                        data-role="radio"
                                        className={cx(internalClasses.radio, {
                                            [internalClasses.checked]: option.value === value
                                        })}
                                    />
                                    {option.label}
                                </MenuItem>
                            );
                        })}
                    </MenuList>
                </Zoom>
            </div>
        </ClickAwayListener>
    );
};

export default React.memo(SearchTypeSelect);
