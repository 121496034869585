import { makeStyles } from 'tss-react/mui';

export default makeStyles()({
    title: {
        padding: 24,
        '& h2': {
            fontWeight: 700,
            fontSize: 20
        }
    },
    alert: {
        padding: 16,
        background: '#FBF1EF',
        borderRadius: 8,
        marginBottom: 24,
        textAlign: 'center'
    },
    header: {
        marginBottom: 8,
        color: '#232323',
        fontSize: 20,
        fontWeight: 700,
        textAlign: 'center'
    },
    subHeader: {
        fontSize: 16,
        color: '#5F5F5F',
        marginBottom: 24,
        textAlign: 'center'
    },
    link: {
        color: '#F5736A',
        cursor: 'pointer'
    },
    formContent: {
        '& >div': {
            gap: 12
        }
    },
    forgotPasswordButton: {
        fontSize: 13,
        fontWeight: 500,
        lineHeight: '16px',
        padding: '20px 24px 12px',
        color: '#282D31',
        textAlign: 'center',
        cursor: 'pointer'
    }
});
