import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';

import { SEARCH_TYPE_COOKIE } from 'app/constants';
import SearchStore from 'app/store/SearchStore';
import useShortcutSearchInput from 'hooks/use-shortcut-search-input';
import useStores from 'hooks/use-stores';
import useSuggestions from 'main/components/SearchBar/hooks/use-suggestions';
import SearchField from 'main/components/SearchBar/SearchField';
import { ChangeTrigger, SearchFieldValue, SearchType } from 'main/components/SearchBar/types';
import { ElasticQueryBuilder } from 'main/modules/elastic';
import useDrawerMenuContext from 'pages/layout/components/DrawerMenuButton/use-drawer-menu-context';
import { setCookie } from 'utils/cookies';
import { stringifyQuery } from 'utils/stringify-query';

interface Props {
    className?: string;
    compact?: boolean;
}

const SearchBar: React.FC<Props> = ({ className, compact }) => {
    const { searchStore, antiBotStore } = useStores();
    const history = useHistory();

    const { close } = useDrawerMenuContext();

    const autoCompleteInputRef = useShortcutSearchInput();

    const { query, software, version, type } = searchStore;
    const value: SearchFieldValue = useMemo(() => ({ query, software, version, type }), [
        query,
        software,
        type,
        version
    ]);

    const updateUrl = useCallback(() => {
        const { query, software, version, type, requestState } = searchStore;
        const state = ElasticQueryBuilder.stateToString(requestState);

        switch (type) {
            case SearchType.simple:
                history.push(stringifyQuery('/search', { query }));
                break;
            case SearchType.product:
                history.push(stringifyQuery('/search', { software, version }));
                break;
            case SearchType.elastic:
                history.push(stringifyQuery('/search', { state }));
                break;
            default:
                break;
        }
    }, [history, searchStore]);

    const refetch = useCallback(() => {
        antiBotStore.randomCollectData();
        const { query, software, version, type, requestState } = searchStore;
        const parameters = { query, software, version, type };
        const state = type === SearchType.elastic ? { ...requestState, searchTerm: query } : requestState;

        searchStore.setParameters({ ...parameters, state });
        setTimeout(() => updateUrl(), 0);
        searchStore.performFetch();
    }, [antiBotStore, searchStore, updateUrl]);

    const handleSubmit = useCallback(() => {
        refetch();
        close();
    }, [close, refetch]);

    const handleChange = useCallback(
        (payload: SearchFieldValue, trigger: ChangeTrigger) => {
            if (payload.type === searchStore.type) {
                searchStore.setParameters({
                    ...payload,
                    state: payload.type === SearchType.elastic ? searchStore.requestState : {}
                });
            } else {
                searchStore.setPageSize(SearchStore.DEFAULT_PAGE_SIZE);
                switch (payload.type) {
                    case SearchType.elastic:
                        searchStore.setParameters({
                            ...payload,
                            state: { sortDirection: 'desc', sortField: 'published' }
                        });
                        break;
                    case SearchType.simple:
                        searchStore.setParameters({
                            ...payload,
                            query: payload.query || 'viewCount:[50 TO *] order:viewCount last 7 days',
                            state: {}
                        });
                        break;
                    case SearchType.product:
                        searchStore.setParameters({
                            ...payload,
                            software: 'grafana',
                            version: '9',
                            state: {}
                        });
                        break;
                    default:
                        searchStore.setParameters({ ...payload, state: {} });
                        break;
                }
            }

            setCookie(SEARCH_TYPE_COOKIE, payload.type, { ['max-age']: Number.MAX_SAFE_INTEGER });

            if (trigger === ChangeTrigger.select) {
                refetch();
            }
        },
        [refetch, searchStore]
    );

    const { suggestions, isLoading } = useSuggestions(value);

    return (
        <SearchField
            autoCompleteInputRef={autoCompleteInputRef}
            className={className}
            value={value}
            onSubmit={handleSubmit}
            onChange={handleChange}
            onInputFieldEnter={handleSubmit}
            suggestions={suggestions}
            loading={isLoading}
            compact={compact}
        />
    );
};

export default observer(SearchBar);
