import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import { Dialog } from '@mui/material';

import useStores from 'hooks/use-stores';
import LoginForm from 'main/components/SearchBar/hooks/use-subscription-dialog/components/LoginForm';
import SubscriptionForm from 'main/components/SearchBar/hooks/use-subscription-dialog/components/SubscriptionForm';
import { View } from 'main/components/SearchBar/hooks/use-subscription-dialog/types';
import { useNotify } from 'pages/components/SnackBarProvider';

import useStyles from './styles';

export default function useSubscriptionDialog() {
    const { classes } = useStyles();

    const history = useHistory();

    const { userStore } = useStores();
    const { editingSubscription } = userStore;
    const { openNotification } = useNotify();

    const [isOpen, setIsOpen] = useState(false);

    const restoreKey = useMemo(() => uuidv4(), []);

    const redirectUrl = useMemo(() => {
        const searchParams = new URLSearchParams(history.location.search);
        searchParams.set('restore_key', restoreKey);
        return [history.location.pathname, searchParams.toString()].join('?');
    }, [history.location.pathname, history.location.search, restoreKey]);

    const [info, setInfo] = useState(null);
    const [view, setView] = useState<View>(View.SUBSCRIPTION);

    const openDialog = useCallback((state) => {
        setIsOpen(true);
        setView(View.SUBSCRIPTION);
        setInfo(state);
    }, []);

    const closeDialog = useCallback(() => setIsOpen(false), []);

    const pruneInfo = useCallback(() => {
        setInfo(null);
        setView(View.SUBSCRIPTION);
    }, []);

    const switchToLogin = useCallback(() => setView(View.LOGIN), []);

    const handleNotify = useCallback(
        (message = '') => {
            openNotification({
                message: (
                    <div className={classes.notification}>
                        <div>{message || `Subscribed successfully to email ${editingSubscription.email}`}</div>
                        <a className={classes.notificationButton} href="/email" target="_blank">
                            {'Manage subscriptions'}
                        </a>
                    </div>
                ),
                level: 'success'
            });
        },
        [classes.notification, classes.notificationButton, editingSubscription.email, openNotification]
    );

    useEffect(() => {
        if (userStore.userLoaded) {
            const handler = ({ search }: { search: string }) => {
                const searchParams = new URLSearchParams(search);
                const restoreKey = searchParams.get('restore_key');
                if (restoreKey) {
                    if (editingSubscription.hasPendingState(restoreKey)) {
                        if (userStore.isAuthorized) {
                            editingSubscription.completePendingState(() => handleNotify());
                        } else {
                            handleNotify('Subscription will be enabled after account activation.');
                        }
                        closeDialog();
                    }
                    searchParams.delete('restore_key');
                    history.replace({ search: searchParams.toString() });
                }
            };

            handler(window.location);
            const unRegister = history.listen(({ search }) => handler({ search }));
            return () => unRegister();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userStore.userLoaded]);

    const handleSubscriptionSave = useCallback(() => {
        if (!userStore.isAuthorized) {
            editingSubscription.savePendingState(restoreKey);
            return switchToLogin();
        }
        editingSubscription.save(() => {
            handleNotify();
            closeDialog();
        });
    }, [closeDialog, editingSubscription, handleNotify, restoreKey, switchToLogin, userStore.isAuthorized]);

    const dialog = useMemo(() => {
        if (!info) return null;

        return (
            <Dialog
                className={classes.root}
                open={isOpen}
                onClose={closeDialog}
                TransitionProps={{ onExited: pruneInfo }}
                PaperProps={{ className: classes.paper }}
            >
                {view === View.SUBSCRIPTION ? <SubscriptionForm onSubscriptionSave={handleSubscriptionSave} /> : null}
                {view === View.LOGIN ? (
                    <LoginForm subscription={editingSubscription} redirectUrl={redirectUrl} />
                ) : null}
            </Dialog>
        );
    }, [
        info,
        classes.root,
        classes.paper,
        isOpen,
        closeDialog,
        pruneInfo,
        view,
        handleSubscriptionSave,
        editingSubscription,
        redirectUrl
    ]);

    return [dialog, openDialog] as const;
}
