import React from 'react';

import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import { NoSsr } from '@mui/material';

import isMacLike from 'utils/is-mac-like';

import useStyles from './styles';

const HotKey: React.FC = () => {
    const { classes } = useStyles();

    return (
        <div className={classes.root}>
            <span className={classes.item}>
                <NoSsr>
                    {isMacLike() ? (
                        <KeyboardCommandKeyIcon sx={{ width: 16, height: 16 }} />
                    ) : (
                        <span className={classes.control}>{'CTRL'}</span>
                    )}
                </NoSsr>
            </span>
            <span className={classes.item}>{'K'}</span>
        </div>
    );
};

export default HotKey;
