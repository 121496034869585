import React, { useCallback, useState } from 'react';

import { LoginView } from 'main/components/SearchBar/hooks/use-subscription-dialog/types';
import SignInFormContent from 'main/pages/components/LoginForm';
import RecoveryFormContent from 'main/pages/components/RecoveryForm';
import SignUpFormContent from 'main/pages/components/SignUpForm';

import useStyles from './styles';

interface Props {
    redirectUrl?: string;
    subscription?: string;
}

const LoginForm: React.FC<Props> = ({ redirectUrl }) => {
    const { classes } = useStyles();
    const [view, setView] = useState<LoginView>(LoginView.SIGN_IN);

    const switchToSignIn = useCallback(() => setView(LoginView.SIGN_IN), []);
    const switchToSignUp = useCallback(() => setView(LoginView.SIGN_UP), []);
    const switchToRecover = useCallback(() => setView(LoginView.RECOVER), []);

    return (
        <>
            <div className={classes.alert}>Looks like you are not logged in. Sign in to set notifications</div>
            <div>
                <div className={classes.header}>Get updates to this search query by email</div>
                <div className={classes.subHeader}>
                    {view === LoginView.SIGN_IN && (
                        <>
                            Don`t have an account?{' '}
                            <span className={classes.link} onClick={switchToSignUp}>
                                Sign up
                            </span>
                        </>
                    )}
                    {view === LoginView.SIGN_UP && (
                        <>
                            Already have an account?{' '}
                            <span className={classes.link} onClick={switchToSignIn}>
                                Sign in
                            </span>
                        </>
                    )}
                    {view === LoginView.RECOVER && (
                        <>
                            Already have an account?{' '}
                            <span className={classes.link} onClick={switchToSignIn}>
                                Sign in
                            </span>
                        </>
                    )}
                </div>
            </div>

            {view === LoginView.SIGN_IN && (
                <>
                    <SignInFormContent
                        className={classes.formContent}
                        showHeader={false}
                        showRecover={false}
                        redirectUrl={redirectUrl}
                    />
                    <div className={classes.forgotPasswordButton} onClick={switchToRecover}>
                        {'Forgot password?'}
                    </div>
                </>
            )}
            {view === LoginView.SIGN_UP && (
                <SignUpFormContent className={classes.formContent} showHeader={false} redirectUrl={redirectUrl} />
            )}
            {view === LoginView.RECOVER && (
                <RecoveryFormContent className={classes.formContent} showHeader={false} redirectUrl={redirectUrl} />
            )}
        </>
    );
};

export default LoginForm;
