import { mapPresetToLabel } from 'pages/Email/components/Scheduler/constants';
import { Intervals, Presets, SchedulerState } from 'pages/Email/components/Scheduler/types';

const WILDCARD = '*';
const PRESET_PATTERNS = {
    [Presets.ASAP]: /^(\*\/)?30 \* \* \* \*$/,
    [Presets.EVERY_DAY]: /^\d+ \d+ \* \* \*$/,
    [Presets.EVERY_MONDAY]: /^\d+ \d+ \* \* 1$/,
    [Presets.FIRST_DAY_OF_MONTH]: /^\d+ \d+ 1 \* \*$/
} as const;

/**
 * @description
 * Workaround to get cron values as close as possible to available ones
 */
function parseCronItem(cronItem: string, options: { min: number; max: number; fallback: number[] }) {
    const parsed = cronItem
        .split(/[,/-]/)
        .map((it) => parseInt(it))
        .filter((it) => Number.isInteger(it))
        .filter((it) => it >= options.min)
        .filter((it) => it <= options.max);
    return parsed.length > 0 ? parsed : options?.fallback;
}

/**
 * @description
 * Assume we can only have following cron strings:
 *  - Days interval: 0 <hours> *\/<days> * *
 *  - Months interval: 0 <hours> 1 *\/<months> *
 *  - Days of week: 0 <hours> * * 1,2,3,4,5
 */
export function parseCronString(cron: string): Partial<SchedulerState> {
    if (typeof cron !== 'string') {
        return {};
    }

    cron = cron.trim();
    if (cron.length === 0) {
        return {};
    }

    const [_minute, hour, day, month, dayOfWeek] = cron.split(' ').filter(Boolean);

    let time = parseInt(hour);
    time = Number.isInteger(time) && time >= 0 && time <= 23 ? time : 10;
    const utc = fromUtc(time);
    time = utc.time;
    const daysOffset = utc.daysOffset;

    for (const preset in PRESET_PATTERNS) {
        const pattern = PRESET_PATTERNS[preset as keyof typeof PRESET_PATTERNS];

        if (pattern.test(cron)) {
            return { preset: preset as Presets, time };
        }
    }

    if (dayOfWeek !== WILDCARD) {
        const intervalValue = parseCronItem(dayOfWeek, { min: 0, max: 6, fallback: [1] });
        return {
            preset: Presets.CUSTOM,
            time,
            interval: Intervals.DAY_OF_WEEK,
            intervalValue: intervalValue.map((value) => getDayOfWeekWithOffset(value, daysOffset))
        };
    }

    if (month !== WILDCARD) {
        const [intervalValue] = parseCronItem(month, { min: 1, max: 12, fallback: [1] });
        return {
            preset: Presets.CUSTOM,
            time,
            interval: Intervals.NTH_MONTH_IN_YEAR,
            intervalValue
        };
    }

    if (day !== WILDCARD) {
        const [intervalValue] = parseCronItem(day, { min: 1, max: 31, fallback: [1] });
        return {
            preset: Presets.CUSTOM,
            time,
            interval: Intervals.NTH_DAY_IN_MONTH,
            intervalValue: getDayOfMonthWithOffset(intervalValue, daysOffset)
        };
    }

    if (hour === WILDCARD) {
        return { preset: Presets.ASAP, time };
    }

    return {};
}

export function getCronStringLabel(cron: string) {
    if (typeof cron !== 'string' || cron.trim().length === 0) {
        return null;
    }
    const parsed = parseCronString(cron);

    const { preset = Presets.CUSTOM, time } = parsed;
    const presetLabel = mapPresetToLabel[preset];
    if (preset === Presets.CUSTOM) {
        return `${presetLabel}: ${cron}`;
    }
    if (preset === Presets.ASAP) {
        return presetLabel;
    }
    if (typeof time === 'number') {
        return `${presetLabel} at ${String(time).padStart(2, '0')}:00`;
    }
    return presetLabel;
}

export function buildCronString(state: SchedulerState): string {
    const { preset, interval, intervalValue } = state;
    const { time, daysOffset } = toUtc(state.time);

    if (preset === Presets.ASAP) {
        return `30 * * * *`;
    }
    if (preset === Presets.EVERY_DAY) {
        return `0 ${time} * * *`;
    }
    if (preset === Presets.EVERY_MONDAY) {
        return `0 ${time} * * ${getDayOfWeekWithOffset(1, daysOffset)}`;
    }
    if (preset === Presets.FIRST_DAY_OF_MONTH) {
        return `0 ${time} ${getDayOfMonthWithOffset(1, daysOffset)} * *`;
    }
    if (interval === Intervals.DAY_OF_WEEK) {
        return `0 ${time} * * ${[intervalValue]
            .flat()
            .map((value) => getDayOfWeekWithOffset(value, daysOffset))
            .join()}`;
    }
    if (interval === Intervals.NTH_DAY_IN_MONTH) {
        return `0 ${time} */${getDayOfMonthWithOffset(intervalValue as number, daysOffset)} * *`;
    }
    if (interval === Intervals.NTH_MONTH_IN_YEAR) {
        return `0 ${time} 1 */${intervalValue} *`;
    }
    // Falling back in unexpected case
    return `0 ${time} * * 1`;
}

function utcInner(hours: number, sign: 1 | -1) {
    const utcOffset = Math.sign(sign) * Math.floor(new Date().getTimezoneOffset() / 60);
    let time: number = hours + utcOffset;
    let daysOffset = 0;

    switch (true) {
        case time > 23:
            time -= 24;
            daysOffset = 1;
            break;
        case time < 0:
            time += 24;
            daysOffset = -1;
            break;
        default:
            break;
    }

    return { time, daysOffset };
}

export function toUtc(hours: number) {
    return utcInner(hours, 1);
}

function fromUtc(hours: number) {
    return utcInner(hours, -1);
}

function getDayOfWeekWithOffset(dayOfWeek: number, offset: number) {
    return (dayOfWeek + offset + 7) % 7;
}

function getDayOfMonthWithOffset(dayOfMonth: number, offset: number) {
    const candidate = dayOfMonth + offset;
    /**
     * Cron not supports "last day of month" and can behave incorrectly
     * after 28th of each month, as not every month has 29th, 30th and 31st day.
     */
    return candidate < 1 || candidate > 28 ? dayOfMonth : candidate;
}
