import React, { useCallback } from 'react';

export default function useRefSetter<T>(...refs: React.Ref<T>[]) {
    const setRef = useCallback(
        (element: T) => {
            refs.forEach((ref) => {
                if (typeof ref === 'function') {
                    return ref(element);
                }
                if (typeof ref === 'object' && ref !== null) {
                    Object.assign(ref, { current: element });
                }
            });
        },
        [refs]
    );

    return setRef;
}
