import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';

import { Button, Tooltip, Zoom } from '@mui/material';

import useStores from 'hooks/use-stores';
import { ButtonState } from 'main/components/SearchBar/components/SubscriptionButton/types';
import useSubscriptionDialog from 'main/components/SearchBar/hooks/use-subscription-dialog';
import { SearchType } from 'main/components/SearchBar/types';

import useStyles from './styles';

const transitionTimeout = { enter: 150, exit: 90 };

const SubscriptionButton: React.FC = () => {
    const { classes, cx } = useStyles();
    const history = useHistory();

    const [subscriptionDialog, openSubscriptionDialog] = useSubscriptionDialog();
    const { userStore, searchStore } = useStores();

    const [buttonState, setButtonState] = React.useState(ButtonState.HIDDEN);
    const buttonText = buttonState === ButtonState.SUBSCRIBED ? 'Subscribed' : 'Subscribe';
    const buttonTooltip = useMemo(() => {
        return buttonState === ButtonState.SUBSCRIBED
            ? 'You are already subscribed to this query, click on button to edit your subscriptions'
            : 'Receive notifications about new vulnerabilities and exploits for this query';
    }, [buttonState]);

    useEffect(() => {
        let isVisible = false;

        if (searchStore.type === SearchType.simple) {
            isVisible = !!searchStore.query?.trim();
        }
        if (searchStore.type === SearchType.elastic) {
            isVisible = searchStore.hasTerms;
        }

        if (isVisible) {
            let hasSubscription = false;
            if (searchStore.type === SearchType.simple) {
                hasSubscription = userStore.subscriptions.some(
                    ({ query, query_type }) => query_type === 'lucene' && searchStore.query?.trim() === query?.trim()
                );
            }
            if (searchStore.type === SearchType.elastic) {
                hasSubscription = userStore.subscriptions.some(
                    ({ query, query_type }) => query_type === 'elastic' && searchStore.query?.trim() === query?.trim()
                );
            }
            setButtonState(hasSubscription ? ButtonState.SUBSCRIBED : ButtonState.NEW);
        } else {
            setButtonState(ButtonState.HIDDEN);
        }
    }, [searchStore.hasTerms, searchStore.query, searchStore.state, searchStore.type, userStore.subscriptions]);

    const handleClick = useCallback(
        (event) => {
            event.preventDefault();
            if (buttonState === ButtonState.SUBSCRIBED) {
                return history.push('/email');
            }

            openSubscriptionDialog(userStore.editingSubscription);
        },
        [buttonState, history, openSubscriptionDialog, userStore.editingSubscription]
    );

    return (
        <>
            <Zoom in={buttonState !== ButtonState.HIDDEN} timeout={transitionTimeout}>
                <Tooltip title={buttonTooltip}>
                    <Button
                        className={cx(classes.button, { [classes.buttonActive]: buttonState === ButtonState.NEW })}
                        onClick={handleClick}
                    >
                        {buttonText}
                    </Button>
                </Tooltip>
            </Zoom>
            {subscriptionDialog}
        </>
    );
};

export default observer(SubscriptionButton);
