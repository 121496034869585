import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

/**
 * @function
 * @description Аналогичен useEffect, но не выполняется при монтировании (первичном рендере)
 * @param effect
 * @param deps
 */
const useSkipOnMountEffect = (effect: EffectCallback, deps?: DependencyList): void => {
    const isMountedRef = useRef(false);

    useEffect(() => {
        if (isMountedRef.current) {
            effect();
        } else {
            isMountedRef.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};

export default useSkipOnMountEffect;
