import { Intervals, Presets } from 'pages/Email/components/Scheduler/types';

export const mapPresetToLabel = {
    [Presets.ASAP]: 'As soon as possible',
    [Presets.EVERY_DAY]: 'Every day',
    [Presets.EVERY_MONDAY]: 'Every monday',
    [Presets.FIRST_DAY_OF_MONTH]: 'First day of month',
    [Presets.CUSTOM]: 'Custom'
};

export const presetOptions = Object.values(Presets).map((value) => ({
    value,
    label: mapPresetToLabel[value]
}));

export const mapIntervalToLabel = {
    [Intervals.DAY_OF_WEEK]: 'Day of week',
    [Intervals.NTH_DAY_IN_MONTH]: 'Day in month',
    [Intervals.NTH_MONTH_IN_YEAR]: 'Month in year'
};

export const intervalOptions = Object.values(Intervals).map((value) => ({
    value,
    label: mapIntervalToLabel[value]
}));

export const timeOptions = Array.from({ length: 24 }, (_, index) => ({
    value: index,
    label: `${String(index).padStart(2, '0')}:00`
}));

export const daysInWeekOptions = [
    { value: 1, label: 'Mo' },
    { value: 2, label: 'Tu' },
    { value: 3, label: 'We' },
    { value: 4, label: 'Th' },
    { value: 5, label: 'Fr' },
    { value: 6, label: 'Sa' },
    { value: 0, label: 'Su' }
];

export const daysIntervalOptions = Array.from({ length: 31 }, (_, index) => ({ value: index + 1 }));

export const monthsIntervalOptions = Array.from({ length: 12 }, (_, index) => ({ value: index + 1 }));
