import { createContext } from 'react';

interface Context {
    isOpen: boolean;
    open: () => void;
    close: () => void;
    toggle: () => void;
}

const defaultValue: Context = {
    isOpen: false,
    open: () => undefined,
    close: () => undefined,
    toggle: () => undefined
};

const drawerMenuContext = createContext(defaultValue);
export default drawerMenuContext;
