export default function debounce<F extends (...args: unknown[]) => void | Promise<void>>(fn: F, time: number) {
    let timer: ReturnType<typeof setTimeout> | undefined = undefined;
    const clear = () => clearTimeout(timer);

    function deferredFunction(...args: Parameters<F>) {
        clear();
        timer = setTimeout(() => fn(...args), time);
    }

    deferredFunction.clear = clear;
    return deferredFunction;
}
