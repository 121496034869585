import { makeStyles } from 'tss-react/mui';

export default makeStyles()({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: 4
    },
    item: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 0,
        color: ' #232323',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#FFFFFF',
        borderRadius: 8,
        minWidth: 24,
        height: 24
    },
    control: {
        fontSize: 10,
        fontWeight: 600,
        paddingLeft: 4,
        paddingRight: 4
    }
});
